@charset "utf-8";

*{
 box-sizing: border-box !important;
 margin: 0px;
 padding: 0;
}

body
{
 box-sizing: border-box !important;
 font-family: 'Helvetica', 'Arial';
 margin: 0;
 overflow-x: hidden;
 padding: 0;
}

html
{
 box-sizing: border-box !important;
 font-size: 1rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
 -webkit-appearance: none;
 margin: 0;
}

input[type=number]{
 -moz-appearance:textfield;
}

.container-form
{
 align-items: center;
 background-color: $white;
 display: flex;
 flex-direction: column;
 justify-content: center;
 margin: 0 auto;
 padding: .5rem;
 width: 100%;
}

.form-error
{
 color: $red;
 display: block;
 margin: 0;
 padding: 0;
 text-align: center;
}

.main
{
 align-items: center;
 display: flex;
 flex-direction: column;
 justify-content: center;
 margin: 0;
 min-height: 100vh;
 padding: 0;
 width: 100%;
}

.section-login
{
 align-items: center;
 display: flex;
 flex-direction: row;
 justify-content: center;
 margin: 0;
 padding: .8rem;
}

.swal2-styled.swal2-cancel
{
 -moz-user-select: none;
 -ms-user-select: none;
 -webkit-user-select: none;
 background-color: gray !important;
 border-radius: 1rem !important;
 border: 1px solid gray !important;
 box-shadow: none;
 color: $white;
 display: inline-block;
 font-weight: 400;
 line-height: 1.5;
 padding: 0.2rem 1.5rem;
 text-align: center;
 user-select: none;
 vertical-align: middle;
 transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.swal2-styled.swal2-confirm
{
 -moz-user-select: none;
 -ms-user-select: none;
 -webkit-user-select: none;
 background-color: $blue !important;
 border-radius: 1rem !important;
 border: 1px solid $blue !important;
 box-shadow: none;
 color: $white;
 display: inline-block;
 font-weight: 400;
 line-height: 1.5;
 padding: 0.2rem 1.5rem;
 text-align: center;
 user-select: none;
 vertical-align: middle;
 transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media(max-width:320px)
{
 h1{font-size: 1.9rem;}
 h2{font-size: 1.7rem;}
 h3{font-size: 1.4rem;}
 h4{font-size: 0.9rem;}
 h5{font-size: .63rem;}
 h6{font-size: .47rem;}
 html{font-size: 0.9rem;}
 input::placeholder{font-size: .70rem !important;}
 input[type="email"]{font-size: .70rem !important;}
 input[type="number"]{font-size: .70rem !important;}
 input[type="password"]{font-size: .70rem !important;}
 input[type="text"]{font-size: .70rem !important;}
 textarea::placeholder{font-size: .70rem !important;}
 textarea{font-size: .70rem !important;}
}

@media(min-width:321px) and (max-width:575px)
{
 h1{font-size: 2rem;}
 h2{font-size: 1.5rem;}
 h3{font-size: 1.17rem;}
 h4{font-size: 1rem;}
 h5{font-size: .73rem;}
 h6{font-size: .57rem;}
 html{font-size: 1rem;}
 input::placeholder{font-size: .71rem !important;}
 input[type="email"]{font-size: .71rem !important;}
 input[type="number"]{font-size: .71rem !important;}
 input[type="password"]{font-size: .71rem !important;}
 input[type="text"]{font-size: .71rem !important;}
 textarea::placeholder{font-size: .71rem !important;}
 textarea{font-size: .71rem !important;}
}

@media(min-width:576px) and (max-width:767px)
{
 h1{font-size: 2.1rem;}
 h2{font-size: 1.6rem;}
 h3{font-size: 1.27rem;}
 h4{font-size: 1.1rem;}
 h5{font-size: .83rem;}
 h6{font-size: .67rem;}
 html{font-size: 1.1rem;}
 input::placeholder{font-size: .72rem !important;}
 input[type="email"]{font-size: .72rem !important;}
 input[type="number"]{font-size: .72rem !important;}
 input[type="password"]{font-size: .72rem !important;}
 input[type="text"]{font-size: .72rem !important;}
 textarea::placeholder{font-size: .72rem !important;}
 textarea{font-size: .72rem !important;}
}

@media(min-width:768px) and (max-width:991px)
{
 h1{font-size: 2.2rem;}
 h2{font-size: 1.7rem;}
 h3{font-size: 1.37rem;}
 h4{font-size: 1.2rem;}
 h5{font-size: .93rem;}
 h6{font-size: .77rem;}
 html{font-size: 1.2rem;}
 input::placeholder{font-size: .73rem !important;}
 input[type="email"]{font-size: .73rem !important;}
 input[type="number"]{font-size: .73rem !important;}
 input[type="password"]{font-size: .73rem !important;}
 input[type="text"]{font-size: .73rem !important;}
 textarea::placeholder{font-size: .73rem !important;}
 textarea{font-size: .73rem !important;}
}

@media(min-width:992px) and (max-width:1199px)
{
 h1{font-size: 2.3rem;}
 h2{font-size: 1.8rem;}
 h3{font-size: 1.47rem;}
 h4{font-size: 1.3rem;}
 h5{font-size: 1.03rem;}
 h6{font-size: .87rem;}
 html{font-size: 1.3rem;}
 input::placeholder{font-size: .74rem !important;}
 input[type="email"]{font-size: .74rem !important;}
 input[type="number"]{font-size: .74rem !important;}
 input[type="password"]{font-size: .74rem !important;}
 input[type="text"]{font-size: .74rem !important;}
 textarea::placeholder{font-size: .74rem !important;}
 textarea{font-size: .74rem !important;}
}

@media(min-width:1200px)
{
 h1{font-size: 2.4rem;}
 h2{font-size: 1.9rem;}
 h3{font-size: 1.57rem;}
 h4{font-size: 1.4rem;}
 h5{font-size: 1.13rem;}
 h6{font-size: 0.97rem;}
 html{font-size: 1.4rem;}
 input::placeholder{font-size: .75rem !important;}
 input[type="email"]{font-size: .75rem !important;}
 input[type="number"]{font-size: .75rem !important;}
 input[type="password"]{font-size: .75rem !important;}
 input[type="text"]{font-size: .75rem !important;}
 textarea::placeholder{font-size: .75rem !important;}
 textarea{font-size: .75rem !important;}
}
